







































































import { Component, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import SendDoubts from '@/components/Doubts/SendDoubts/SendDoubts.vue';
import Footer from '@/components/Footer/Footer.vue';
import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import ShowDoubtsLoading from './ShowDoubtsLoading.vue';
import ReplyDoubt from './components/ReplyDoubt.vue';
import Actions from './components/Actions/index.vue';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { DoubtSent, Reply, Action } from '@/globalInterfaces/Doubts';

import { SaveReply as ISaveReply } from '@/services/Doubts/DoubtsInterface';

import mixinDoubts from '@/mixins/doubts';

const USER_TYPE_NUMBER_MONITOR = 0;
const USER_TYPE_NUMBER_STUDENT = 1;
const TITLE_BUTTON_COMMENTS = 'Responder comentário';
const TITLE_BUTTON_DOUBT = 'Responder Dúvida';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    ShowDoubtsLoading,
    SendDoubts,
    ReplyDoubt,
    ButtonMovie,
    Footer,
    ContainerFluid,
    Actions,
  },
})
export default class ShowDoubts extends Mixins(mixinDoubts) {
  private isLoadingDoubts = true;
  private isLoadingReplies = true;
  private userLiked = false;
  private userDisliked = false;

  private replies: Array<Reply> = [];
  private doubt: DoubtSent | null = null;

  created() {
    this.getDoubt();
    this.getReplies();
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isMovie() {
    return this.$store.getters.isMovie;
  }

  get checkMonitorResponse() {
    const monitorResponse = this.replies.find(
      (response) => response.user_type === USER_TYPE_NUMBER_MONITOR,
    );

    return !!monitorResponse;
  }

  get setTextButton() {
    return this.replies.length ? TITLE_BUTTON_COMMENTS : TITLE_BUTTON_DOUBT;
  }

  get showLoading() {
    return this.isLoadingDoubts || this.isLoadingReplies;
  }

  get doubtId() {
    return Number(this.$route.params.id);
  }

  get userProfile() {
    return this.$store.getters.profile;
  }

  get doubtFromUser() {
    return this.doubt?.userID === this.userProfile?.idstudent;
  }

  async getDoubt() {
    try {
      this.isLoadingDoubts = true;

      const query = {
        ...(this.doubtId && { id: [this.doubtId] }),
        logged: true,
      };

      const { doubts } = await this.DoubtsService.getDoubts(query);

      if (doubts && doubts.length) {
        [this.doubt] = doubts;
        this.checkUserHasAction();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar a dúvida.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingDoubts = false;
    }
  }

  async getReplies() {
    try {
      this.isLoadingReplies = true;

      const response = await this.DoubtsService.getRepliesDoubts(this.doubtId);

      if (response && response.length) {
        this.replies = response;
      }
    } catch (error) {
      if (error?.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND) return;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao recuperar as respostas.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoadingReplies = false;
    }
  }

  checkUserHasAction() {
    if (!this.doubt) return;

    const hasLike = this.findUserAction(this.doubt.likes);
    const hasDislike = this.findUserAction(this.doubt.dislikes);

    if (hasLike) {
      this.userLiked = true;
      return;
    }

    if (hasDislike) {
      this.userDisliked = true;
    }
  }

  findUserAction(actions: Action[]) {
    if (!actions?.length) return undefined;

    return actions.find((user: Action) => user.userID === this.userProfile?.idstudent);
  }

  handleGoBack() {
    const { from, to } = this.$store.getters['RouterHistory/routesFromTo'];
    const routerFrom = from.fullPath;
    const routerTo = to.fullPath;

    if (routerFrom !== routerTo) {
      this.$router.push(routerFrom);
    } else {
      this.$router.push({
        name: 'Doubts',
      });
    }
  }

  async sendReply(content: string) {
    try {
      const data: ISaveReply = {
        id: this.doubtId,
        content,
        userType: USER_TYPE_NUMBER_STUDENT,
      };

      const response = await this.DoubtsService.saveReply(data);

      if (response?.status === STATUS_RESPONSE_API.SUCCESS_CREATED) {

        const newReply: Reply = {
          ID: data.id,
          status: 0,
          postID: data.id,
          user_type: 1,
          userID: this.userProfile?.idstudent || 0,
          registry_date: new Date(),
          content,
          showAnswer: false,
          profile: {
            idstudent: this.userProfile?.idstudent || 0,
            usersname: this.userProfile?.name || '',
            avatar: this.userProfile?.avatar || '',
          },
        };

        this.replies = [...this.replies, newReply];

        return true;
      }

      return false;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar uma resposta.',
        status: 'error',
      });
      console.error(error);
      return false;
    }
  }

  setBreadCrumbs() {
    this.$breadcrumb.set(
      [
        { title: 'Dúvida', to: '/duvidas' },
        { title: 'Visualizar Dúvida', to: null },
      ],
    );
  }
}
